import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import SuccessIcon from '@babelcoder/gatsby-theme-base/assets/images/success.svg';

const StyledSuccessIcon = styled(SuccessIcon)`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const HighlightText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const EmailLink = styled.a.attrs({ href: 'mailto:babelcoder@gmail.com' })`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const CompleteEnrollment = () => {
  const [next24HoursDate, setNext24HoursDate] = useState(null);
  const setVerifyDate = useCallback(() => {
    const now = new Date();
    const next = now.setHours(now.getHours() + 24);
    const nextDate = new Intl.DateTimeFormat('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(next);
    const nextTime = new Intl.DateTimeFormat('th-TH', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(next);

    setNext24HoursDate(`${nextDate} เวลา ${nextTime} น.`);
  }, [setNext24HoursDate]);

  useEffect(() => {
    setVerifyDate();
  }, [setVerifyDate]);

  return (
    <div>
      <StyledSuccessIcon></StyledSuccessIcon>
      <div>
        เราจะดำเนินการตรวจสอบหลักฐานการชำระเงินของท่านภายในวันที่{' '}
        <HighlightText>{next24HoursDate}</HighlightText>{' '}
        หากพ้นระยะเวลาดังกล่าวแล้วยังไม่สามารถเข้าใช้งานคอร์สนี้ได้ โปรดติดต่อ{' '}
        <EmailLink>babelcoder@gmail.com</EmailLink>
      </div>
    </div>
  );
};

export default CompleteEnrollment;
