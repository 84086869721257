import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import NotRegisterOnlyGuard from '../NotRegisterOnlyGuard';
import ClassRoomContext from './context';

const Wrapper = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacers.normal};
    max-width: 992px;
    margin: 0 auto;

    ${up('large')} {
      flex-direction: row;
    }
  `}
`;

const Plan = styled.article`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px 0px ${theme.colors.neutral.gray400};
    padding: 1rem;
    flex: 1;

    border-radius: ${theme.round.xlarge};
    background-color: ${theme.colors.neutral.white};
  `}
`;

const PlanTitle = styled.h1`
  ${({ theme }) => css`
    text-align: center;
  `}
`;

const PlanDetails = styled.div`
  margin: 1rem 0;
`;

const PlanDetailsItem = styled.div`
  ${({ theme, tick }) => css`
    display: flex;

    ${tick
      ? css`
          &::before {
            content: '✔';
            margin-right: ${theme.spacers.normal};
            color: ${theme.colors.main.primary};
          }
        `
      : css`
          &::before {
            content: '✘';
            margin-right: ${theme.spacers.normal};
            color: ${theme.colors.main.danger};
          }
        `}
  `}
`;

const PlanPrice = styled.div`
  ${({ theme, primary }) => css`
    margin-top: auto;
    text-align: center;
    font-size: ${theme.fontSizes.xxlarge};
    color: ${primary
      ? theme.colors.main.primary
      : theme.colors.neutral.gray1000};
  `}
`;

const SelectPlanButton = styled.button`
  ${({ theme, primary }) => css`
    display: block;
    margin: 1rem 0;
    padding: ${theme.spacers.small} ${theme.spacers.small};
    font-size: ${theme.fontSizes.medium};
    color: ${primary ? theme.colors.neutral.white : theme.colors.neutral.black};
    border-radius: 2rem;
    border: ${primary ? 'none' : `1px solid ${theme.colors.main.primary}`};
    outline: none;
    transition: background-color 0.25s;
    background-color: ${primary
      ? theme.colors.main.primary
      : theme.colors.neutral.white};
    cursor: pointer;
  `}
`;

const SelectEnroll = ({ course: { slug, livePrice, videoPrice } }) => {
  const { goToNextStep, setCourseType } = useContext(ClassRoomContext);

  return (
    <NotRegisterOnlyGuard slug={slug}>
      <Wrapper>
        <Plan>
          <PlanTitle>รอบสอนสด</PlanTitle>
          <PlanDetails>
            <PlanDetailsItem tick>
              คอร์สสอนสดออนไลน์เรียนได้ในทุกอุปกรณ์
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              มีบันทึกการสอน ดูย้อนหลังและทบทวนซ้ำได้
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              มีเอกสารและซอร์จโค้ดประกอบการสอน
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              พร้อม Workshop ระดับใช้งานจริง
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              สอบถามปัญหาได้ทันทีระหว่างเรียนและหลังเรียน
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              มีแบบฝึกหัดเพื่อทบทวนในแต่ละบทเรียน
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              มีแบบทดสอบความเข้าใจท้ายคอร์ส
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              ใบรับรอง (Certificate) การเข้าอบรมหลักสูตรนี้*
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              ฟรี คอร์สหรือเนื้อหาปูพื้นฐานก่อนเรียน
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              ฟรี เนื้อหาพิเศษเพิ่มเติมหลังเรียน
            </PlanDetailsItem>
          </PlanDetails>
          <PlanPrice primary>฿ {livePrice.toLocaleString()}</PlanPrice>
          <SelectPlanButton
            primary
            onClick={() => {
              goToNextStep();
              setCourseType('live');
            }}
          >
            เลือกคอร์สนี้
          </SelectPlanButton>
        </Plan>
        <Plan>
          <PlanTitle>วิดีโอบันทึกการสอน</PlanTitle>
          <PlanDetails>
            <PlanDetailsItem tick>คอร์สวิดีโอจากบันทึกการสอน</PlanDetailsItem>
            <PlanDetailsItem tick>
              สามารถดูย้อนหลังและทบทวนซ้ำได้
            </PlanDetailsItem>
            <PlanDetailsItem tick>เข้าเรียนได้ในทุกอุปกรณ์</PlanDetailsItem>
            <PlanDetailsItem tick>
              พร้อม Workshop ระดับใช้งานจริง
            </PlanDetailsItem>
            <PlanDetailsItem tick>
              มีเอกสารและซอร์จโค้ดประกอบการเรียน
            </PlanDetailsItem>
            <PlanDetailsItem tick>สามารถสอบถามปัญหาได้</PlanDetailsItem>
            <PlanDetailsItem tick>
              ฟรี คอร์สหรือเนื้อหาปูพื้นฐานก่อนเรียน
            </PlanDetailsItem>
            <PlanDetailsItem>
              ไม่มีแบบฝึกหัดเพื่อทบทวนในทุกบทเรียน
            </PlanDetailsItem>
            <PlanDetailsItem>ไม่มีแบบทดสอบความเข้าใจท้ายคอร์ส</PlanDetailsItem>
            <PlanDetailsItem>
              ไม่มีใบรับรอง (Certificate) การเข้าอบรมหลักสูตรนี้
            </PlanDetailsItem>
            <PlanDetailsItem>
              ไม่มีสิทธิ์เข้าถึงเนื้อหาพิเศษเพิ่มเติมหลังเรียน
            </PlanDetailsItem>
          </PlanDetails>
          <PlanPrice>฿ {videoPrice.toLocaleString()}</PlanPrice>
          <SelectPlanButton
            onClick={() => {
              goToNextStep();
              setCourseType('video');
            }}
          >
            เลือกคอร์สนี้
          </SelectPlanButton>
        </Plan>
      </Wrapper>
      <p>
        *
        ใบรับรองการผ่านหลักสูตรระบบจะดำเนินการออกให้เฉพาะผู้เข้าอบรมที่เข้ารับการอบรมไม่น้อยกว่า
        80% ของเวลาอบรมรวม (เช่น หลักสูตรอบรม 5 วัน
        ผู้เข้าอบรมสามารถขาดการอบรมได้ 1 วัน)
        โดยผู้สอนจะมีการเช็คชื่อผู้เข้าอบรมในระหว่างการสอน
      </p>
    </NotRegisterOnlyGuard>
  );
};

export default SelectEnroll;
