import React, { useState, useRef, useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { useForm } from 'react-hook-form';
import {
  getFirebaseFirestore,
  getFirebaseStorage,
} from '@babelcoder/gatsby-plugin-firebase';
import * as yup from 'yup';

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import Checkbox from '@babelcoder/gatsby-theme-base/src/components/Checkbox';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg';
import numToPrice from '@babelcoder/gatsby-theme-courses/src/utils/numToPrice';
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer';
import NotRegisterOnlyGuard from '../NotRegisterOnlyGuard';
import ClassRoomContext from './context';

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

const RegisterForm = styled.form`
  ${({ theme }) => css`
    max-width: 768px;
    margin: ${theme.spacers.normal} auto;
    padding: 0 ${theme.spacers.normal};
  `}
`;

const HighlightText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const Summary = styled.div`
  margin-bottom: ${({ theme }) => theme.spacers.normal};
`;

const AccountBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacers.normal};
    border: 1px solid ${theme.colors.neutral.gray600};
    border-radius: ${theme.round.normal};
  `}
`;

const UploadWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template:
      'message'
      'preview'
      'button'
      'error';
    gap: ${theme.spacers.normal};
    margin-top: ${theme.spacers.normal};

    ${up('small')} {
      grid-template:
        'message preview'
        'message button'
        'message error' / 1fr 200px;
    }
  `}
`;

const UploadMessage = styled.div`
  grid-area: message;
`;

const FileInput = styled.input.attrs({
  type: 'file',
  accept: SUPPORTED_FORMATS.join(', '),
})`
  display: none;
`;

const PreviewImage = styled.img.attrs({
  src:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNcsH1OPQAGZgJ0oRvLAwAAAABJRU5ErkJggg==',
  alt: 'หลักฐานการชำระค่าลงทะเบียนProfile Image',
})`
  grid-area: preview;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.neutral.gray300};
`;

const UploadButton = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    grid-area: button;
    width: 200px;
    margin: 0 auto;
    padding: ${theme.spacers.xsmall};
    background-color: ${theme.colors.neutral.gray500};
    border: none;
    outline: none;
    cursor: pointer;
  `}
`;

const ErrorMessage = styled.div`
  grid-area: error;
  color: ${({ theme }) => theme.colors.main.danger};
`;

const Divider = styled.div`
  ${({ theme }) => css`
    height: 1px;
    background-color: ${theme.colors.neutral.gray400};
    margin: ${theme.spacers.normal} 0;
  `}
`;

const buttonStyle = css`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;
  `}
`;

const RegisterButton = styled.button.attrs(({ $valid }) => ({
  type: 'submit',
  disabled: !$valid,
}))`
  ${buttonStyle};

  ${({ theme, $valid }) => css`
    background-color: ${theme.colors.neutral.gray700};

    ${$valid &&
    css`
      background-color: ${theme.colors.main.primary};
      cursor: pointer;
    `}
  `}
`;

const TermsAndConditionsTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1000};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const TermsAndConditionsDetails = styled.div`
  ${({ theme }) => css`
    height: 150px;
    overflow-y: scroll;
    border: 1px solid ${theme.colors.neutral.gray300};
  `}
`;

function Purchase({
  course: { slug, title, livePrice, videoPrice, termsAndConditions },
}) {
  const { goToNextStep, courseType } = useContext(ClassRoomContext);
  const isLive = courseType === 'live';
  const price = isLive ? livePrice : videoPrice;
  const [isLoading, setIsLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const previewImageRef = useRef(null);
  const imageFileRef = useRef(null);
  const { handleSubmit, register, triggerValidation, errors, watch } = useForm({
    validationSchema: yup.object().shape({
      image: yup
        .mixed()
        .test('fileSize', `ขนาดไฟล์ห้ามเกิน ${FILE_SIZE / 1024} KB`, (value) =>
          value.length > 0 ? value[0].size <= FILE_SIZE : true
        )
        .test(
          'fileFormat',
          `สนุนประเภทไฟล์ ${SUPPORTED_FORMATS.join(', ')} เท่านั้น`,
          (value) =>
            value.length > 0 ? SUPPORTED_FORMATS.includes(value[0].type) : true
        ),
    }),
  });
  const image = watch('image');
  const imageFieldRef = useCallback(
    (e) => {
      register(e);
      imageFileRef.current = e;
    },
    [register, imageFileRef]
  );

  const changeAccept = useCallback(
    (accept) => {
      setAccept(accept);
    },
    [setAccept]
  );

  const uploadImageToStore = useCallback(
    async (file) => {
      try {
        const firestore = await getFirebaseFirestore();
        const firebaseStorage = await getFirebaseStorage();
        const storageRef = firebaseStorage.ref(
          `users/${user.uid}/registrations/${slug}/${file.name}`
        );
        const snapshot = await storageRef.put(file);
        const imageURL = snapshot.ref.toString();
        await firestore
          .collection(`users/${user.uid}/registrations`)
          .doc(slug)
          .set({
            uid: user.uid,
            email: user.email,
            couponId: null,
            createdAt: new Date(),
            updatedAt: new Date(),
            slipURL: imageURL,
            type: 'classroom',
            courseType,
            price: price,
            success: false,
          });
        await firestore.doc(`users/${user.uid}`).set(
          {
            registrations: {
              [slug]: true,
            },
          },
          { merge: true }
        );
      } catch (err) {}
    },
    [price, slug, user.uid]
  );

  const submit = useCallback(
    async (value) => {
      try {
        setIsLoading(true);
        await uploadImageToStore(value.image[0]);
        setIsLoading(false);
        goToNextStep();

        if (typeof fbq === 'function') {
          // eslint-disable-next-line no-undef
          fbq('track', 'Purchase', {
            value: price,
            currency: 'THB',
            content_type: 'course',
            content_name: title,
            content_ids: [slug],
          });
        }
      } catch (error) {
        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'การดำเนินการไม่สำเร็จ',
            message: 'เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาติดต่อผู้ดูแลระบบ',
          },
        });
      }
    },
    [uploadImageToStore, dispatch]
  );

  const previewImage = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (!file) return;

      previewImageRef.current.src = URL.createObjectURL(file);

      previewImageRef.current.onload = () => {
        URL.revokeObjectURL(previewImageRef.current.src);
        triggerValidation('image');
      };
    },
    [previewImageRef, triggerValidation]
  );

  const uploadImage = useCallback(() => {
    imageFileRef.current.click();
  }, [imageFileRef]);

  return (
    <NotRegisterOnlyGuard slug={slug}>
      <RegisterForm onSubmit={handleSubmit(submit)}>
        <Summary>
          คุณกำลังสั่งซื้อคอร์สนี้ในรูปแบบ{' '}
          <HighlightText>
            {isLive ? 'คอร์สอบรม' : 'บันทึกการสอน'}{' '}
          </HighlightText>
          โปรดดำเนินการชำระค่าลงทะเบียนคอร์ส{' '}
          <HighlightText>{title}</HighlightText> เป็นจำนวน{' '}
          <HighlightText>{numToPrice(price)}</HighlightText> บาท
          ผ่านบัญชีธนาคารตามรายละเอียดนี้
        </Summary>
        <AccountBox>
          <div>{process.env.GATSBY_REGISTER_ACCOUNT_BANK}</div>{' '}
          <div>ชื่อบัญชี {process.env.GATSBY_REGISTER_ACCOUNT_NAME}</div>
          <div>บัญชีเลขที่ {process.env.GATSBY_REGISTER_ACCOUNT_NO}</div>
        </AccountBox>
        <UploadWrapper>
          <UploadMessage>
            หากท่านได้ชำระค่าลงทะเบียนเรียบร้อยแล้ว{' '}
            <HighlightText>โปรดแนบหลักฐานการชำระเงิน</HighlightText> เช่น
            สลิปแสดงหลักฐานการโอน ผ่านช่องทางการอัพโหลดนี้
            เราจะดำเนินการตรวจสอบหลักฐานการชำระเงินภายใน 24 ชั่วโมง
            <br />
            <p>
              {isLive
                ? 'คุณจะสามารถเข้าสู่หน้าคอร์สได้ทันทีเมื่อระบบอนุมัติคอร์สแล้วโดยเราจะแจ้งเตือนการเข้าอบรมให้กับคุณทางอีเมล์ล่วงหน้า 7 วัน'
                : 'คุณจะสามารถเข้าสู่หน้าคอร์สได้ทันทีเมื่อระบบอนุมัติคอร์สแล้วโดยบันทึกการสอนทั้งหมดจะแสดงครบถ้วนในระบบหลังการอบรมเสร็จสิ้นภายใน 7 วัน'}
            </p>
            <p>
              (หากท่านต้องการใบเสนอราคา ใบแจ้งหนี้ หรือใบเสร็จรับเงิน
              กรุณาติดต่อ <HighlightText>babelcoder@gmail.com</HighlightText> )
            </p>
          </UploadMessage>
          <PreviewImage ref={previewImageRef} />
          <UploadButton onClick={uploadImage}>แนบหลักฐานการชำระ</UploadButton>
          {errors.image && <ErrorMessage>{errors.image.message}</ErrorMessage>}
          <FileInput
            type="file"
            name="image"
            ref={imageFieldRef}
            onChange={previewImage}
          />
        </UploadWrapper>
        <Divider></Divider>
        {isLoading ? (
          <LoadingImage></LoadingImage>
        ) : (
          <>
            <TermsAndConditionsTitle>
              เงื่อนไขการสมัครและใช้งานคอร์ส
            </TermsAndConditionsTitle>
            <TermsAndConditionsDetails>
              <MDXRenderer>{termsAndConditions}</MDXRenderer>
            </TermsAndConditionsDetails>
            <Checkbox onChange={changeAccept} htmlFor="accept">
              ฉันยอมรับเงื่อนไขการสมัครและใช้งานคอร์ส
            </Checkbox>
            <RegisterButton
              $valid={accept && image?.length > 0 && !errors.image}
            >
              ลงทะเบียน
            </RegisterButton>
          </>
        )}
      </RegisterForm>
    </NotRegisterOnlyGuard>
  );
}

export default Purchase;
