import React, { useState, useMemo, useCallback } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import styled, { css } from 'styled-components';

import SelectEnroll from './SelectEnroll';
import Purchase from './Purchase';
import CompleteEnrollment from './CompleteEnrollment';
import ClassRoomContext from './context';

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1100px;
    margin: ${theme.spacers.normal} auto;
    padding: 0 ${theme.spacers.normal};
  `}
`;

const StyledStep = styled(Step)`
  cursor: not-allowed !important;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    font-size: ${theme.fontSizes.large};
    margin: ${theme.spacers.normal} auto;

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

export default function Register(course) {
  const [step, setStep] = useState(1);
  const [courseType, setCourseType] = useState();
  const Content = [SelectEnroll, Purchase, CompleteEnrollment][step - 1];
  const title = [
    'เลือกรูปแบบการเรียน',
    'ชำระค่าลงทะเบียน',
    'การดำเนินการสำเร็จ',
  ][step - 1];

  const goToNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const contextValue = useMemo(
    () => ({
      step,
      courseType,
      goToNextStep,
      setCourseType,
    }),
    [step, courseType, goToNextStep, setCourseType]
  );

  return (
    <ClassRoomContext.Provider value={contextValue}>
      <Wrapper>
        <Stepper
          styleConfig={{
            activeBgColor: '#17D8A9',
            completedBgColor: '#37BC9B',
          }}
          activeStep={step - 1}
        >
          <StyledStep label="เลือกรูปแบบ" />
          <StyledStep label="ชำระเงิน" />
          <StyledStep label="เสร็จสิ้น" />
        </Stepper>
        <Title>{title}</Title>
        <Content course={course} />
      </Wrapper>
    </ClassRoomContext.Provider>
  );
}
