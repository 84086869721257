import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxArea = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
  margin-right: ${({ theme }) => theme.spacers.normal};
`

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const CheckboxMark = styled.span`
  ${({ theme, $checked }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 5px;
    border: 2px solid ${theme.colors.neutral.gray1000};
    transition: all 0.1s ease-out;

    &::after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0;
      width: 0;
      border-radius: 5px;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.1s ease-out;
    }

    ${$checked &&
    css`
      background-color: #ffffff;
      border-radius: 5px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid ${theme.colors.neutral.gray1000};

      &::after {
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid ${theme.colors.main.primary};
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
      }
    `}
  `}
`

export default function Checkbox({
  onChange,
  defaultValue,
  children,
  htmlFor,
}) {
  const [checked, setChecked] = useState(defaultValue || false)

  const change = useCallback(() => {
    const newValue = !checked

    setChecked(newValue)
    onChange(newValue)
  }, [checked, setChecked, onChange])

  return (
    <CheckboxWrapper onClick={change}>
      <CheckboxArea>
        <CheckboxInput id={htmlFor}></CheckboxInput>
        <CheckboxMark $checked={checked}></CheckboxMark>
      </CheckboxArea>
      <label htmlFor={htmlFor}>{children}</label>
    </CheckboxWrapper>
  )
}
